<template>
  <div class="page">
    <!-- 搜索 -->
    <div class="search_box">
      <div class="in_box">
        <div class="l_box">
          <img src="./img/personnelTransfer/search.png" alt="" />
          <div class="input_box">
            <input
              v-model="keyWork"
              type="text"
              placeholder="搜索成员昵称"
              onkeyup="this.value=this.value.replace(/[, ]/g,'')"
              v-on:input="changeUser()"
            />
          </div>
        </div>
        <div class="r_box">完成</div>
      </div>
    </div>
    <!-- 人员列表 -->
    <div class="list" @scroll.passive="getScroll($event)" ref="scollElement">
      <div
        @click="choiseOne(item)"
        class="out_box"
        v-for="(item, index) in list"
        :key="index"
      >
        <div :class="['item', { bg: item.isSelect == true }]">
          <div class="pic_box">
            <img
              v-if="item.avatar == ''"
              src="./img/workOrderDetail/default_avatar.png"
              alt=""
            />
            <img v-else :src="item.avatar" />
          </div>
          <div v-if="item.isSelect == true" class="name black">
            {{ item.name }}
          </div>
          <div v-else class="name">{{ item.name }}</div>
          <div style="flex: 1"></div>
          <div v-if="item.isSelect == true" class="select blue"></div>
          <div v-else class="select"></div>
        </div>
      </div>
      <div class="no_more" v-if="no_more == true || list.length == 0">
        没有更多了
      </div>
    </div>
    <!-- 按钮 -->
    <div class="btn_box">
      <div class="btn_l btn" @click="goBack()">取消</div>
      <div class="btn_r btn" @click="reassignment()">立即转派</div>
    </div>
    <div class="empty_box"></div>
    <!-- 转派提示弹框 -->
    <v-dialog
      confirmButtonColor="#3680FF"
      v-model="isDialog_message"
      title="提示"
      @confirm="confirm_message"
    >
      <p class="transfer_message">是否转派？</p>
    </v-dialog>
    <!-- 转派成功弹框 -->
    <v-dialog
      confirmButtonColor="#3680FF"
      confirmButtonText="好"
      :showCancelButton="false"
      v-model="isDialog_success"
      @confirm="confirm_success"
    >
      <p class="transfer_success">您已转派成功~</p>
    </v-dialog>
  </div>
</template>

<script>
import { receiverListUrl, redeployWorkOrderUrl } from "./api.js";
export default {
  name: "personnelTransfer",
  data() {
    return {
      isDialog_message: false,
      isDialog_success: false,
      keyWork: "",
      list: [],
      no_more: false,

      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      total: null,

      id: null,
      men_id: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getList();
  },
  methods: {
    reassignment() {
      //转派按钮
      if (this.men_id == null) {
        this.$toast("请选择转派人员！");
      } else {
        this.isDialog_message = true;
      }
    },
    getScroll(event) {
      //滚动条到底触发
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      if (
        Number(scrollBottom.toFixed(1)) < 1 &&
        this.list.length < this.total
      ) {
        console.log(123);
        if (this.requestData.curPage == 1) {
          this.requestData.curPage = 2;
        }
        let params = {
          pageNum: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
          userId: this.userId,
          nickName: this.keyWork,
        };
        this.$axios
          .get(`${receiverListUrl}`, {
            params: params,
          })
          .then((res) => {
            if (res.code === 200) {
              let list1 = res.data.records;
              list1.forEach((element) => {
                element.isSelect = false;
                this.list.push(element);
              });
              if (this.list.length == res.data.total) {
                this.no_more = true;
              }
            }
          });
      }
    },
    changeUser() {
      //搜索成员昵称
      console.log(this.keyWork);
      clearTimeout(this.timer);
      this.requestData.curPage = 1;
      this.timer = setTimeout(() => {
        let params = {
          pageNum: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          userId: this.userId,
          nickName: this.keyWork,
        };
        this.$axios
          .get(`${receiverListUrl}`, {
            params: params,
          })
          .then((res) => {
            if (res.code === 200) {
              this.list = [];
              let list1 = res.data.records;
              list1.forEach((element) => {
                element.isSelect = false;
                this.list.push(element);
              });
              if (this.list.length == res.data.total) {
                this.no_more = true;
              }
            }
          });
      }, 500);
    },
    confirm_message() {
      let params = {
        id: this.id,
        receiverId: this.men_id,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${redeployWorkOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.isDialog_message = false;
            this.isDialog_success = true;
            this.$router.replace({
              name: "myWorkOrder",
            });
          }
        });
    },
    confirm_success() {
      this.isDialog_success = false;
      this.men_id = null;
      this.list.forEach((item, index) => {
        item.isSelect = false;
      });
    },
    choiseOne(data) {
      //人员item点击事件
      if (data.isSelect == false) {
        this.list.forEach((item, index) => {
          item.isSelect = false;
        });
        data.isSelect = true;
        this.men_id = data.id;
        console.log(this.men_id);
      } else {
        this.men_id = null;
        data.isSelect = false;
        console.log(this.men_id);
      }
    },
    goBack() {
      //取消按钮
      this.$router.push({
        name: "myWorkOrder",
      });
    },
    getList() {
      //获取转派人员list
      let params = {
        pageNum: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        nickName: this.keyWork,
      };
      this.$axios
        .get(`${receiverListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            // let list1 = res.data.records
            res.data.records.forEach((element) => {
              element.isSelect = false;
              this.list.push(element);
            });
            if (this.list.length == res.data.total) {
              this.no_more = true;
            }
            this.total = res.data.total;
          }
        });

      console.log(this.list);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 80px;
  box-sizing: border-box;

  .transfer_message {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
  }

  .transfer_success {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    padding: 45px 0;
  }

  .btn_box {
    width: 100%;
    height: 66px;
    line-height: 66px;
    text-align: center;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    margin-top: 170px;
    // position: fixed;
    // bottom: 230px;
    // left: 0;

    .btn {
      flex: 1;
    }

    .btn_l {
      box-sizing: border-box;
      margin-right: 39px;
      height: 66px;
      border-radius: 10px;
      border: 2px solid;
      font-size: 30px;
      color: #3680ff;
    }

    .btn_r {
      box-sizing: border-box;
      margin-left: 39px;
      height: 66px;
      border-radius: 10px;
      border: 2px solid;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    }
  }

  .list {
    width: 100%;
    // margin-top: 66px;
    box-sizing: border-box;
    height: 804px;
    overflow: hidden;
    overflow-y: auto;

    .no_more {
      width: 100%;
      height: 100px;
      line-height: 100px;
      text-align: center;
      font-size: 28px;
      color: #a8a9ab;
      background-color: #f7f7f7;
    }

    .out_box {
      .item {
        background: #ffffff;
        width: 100%;
        height: 134px;
        box-sizing: border-box;
        padding-left: 42px;
        padding-right: 32px;
        display: flex;
        align-items: center;

        .select {
          width: 28px;
          height: 28px;
          border: 2px solid rgba(0, 0, 0, 0.14);
          border-radius: 50%;
        }

        .blue {
          background: #3680ff;
          color: #3680ff;
        }

        .name {
          font-size: 32px;
          color: rgba(0, 0, 0, 0.72);
          margin-left: 26px;
        }

        .black {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }

        .pic_box {
          width: 96px;
          height: 96px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .bg {
        background: #fbfbfb;
      }
    }
  }

  .empty_box {
    width: 100%;
    height: 30px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
  }

  .search_box {
    width: 100%;
    height: 66px;
    padding: 0 28px 0 42px;
    box-sizing: border-box;
    background-color: #fff;
    // position: fixed;
    // top: 30px;
    // left: 0;

    .in_box {
      width: 100%;
      height: 100%;
      display: flex;

      .l_box {
        width: 606px;
        height: 66px;
        background: rgba(0, 0, 0, 0.07);
        border-radius: 33px;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
        align-items: center;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);

        img {
          width: 28px;
          height: 36px;
        }

        .input_box {
          flex: 1;
          height: 100%;
          padding-left: 14px;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            background-color: transparent;
          }
        }
      }

      .r_box {
        flex: 1;
        font-size: 30px;
        line-height: 66px;
        color: rgba(0, 0, 0, 0.25);
        text-align: right;
      }
    }
  }
}
</style>
